import React, { useState } from 'react';
import './App.css';

function App() {
  const [isModalOpen, setModalOpen] = useState(true);

  const handleSelection = () => {
    setModalOpen(false); // Close the modal
  };

  return (
    <div className="App">
      {/* If the modal is open, display the modal overlay and content */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <p>Are you a virgin?</p>
            <div className="button-container">
              <button onClick={handleSelection} className="button yes-button">
                Yes
              </button>
              <button onClick={handleSelection} className="button no-button">
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Main content of the app */}
      <div className="app-content">
        <header className="app-header" style={{
          background: 'linear-gradient(to bottom, #e0a64b 0%, #e0c34b 100%)',
          height: '100vh',
          width: '100%',
        }}>
          {/* Header content goes here */}
        </header>
      </div>
    </div>
  );
}

export default App;
